import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import Base from "./styles/Base"
import 'popper.js'
import firebase from 'firebase'
import firebaseConfig from './firebaseConfig'

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
    <>
        <Base />
        <App />
        <firebase />
    </>
    ,
    document.getElementById('root')
)
serviceWorker.unregister()
