const firebaseConfig = {
    apiKey: "AIzaSyAvPYGxR1m30HUjSPbSnDAPPOfgNZBVE7U",
    authDomain: "mra-web-app.firebaseapp.com",
    databaseURL: "https://mra-web-app.firebaseio.com",
    projectId: "mra-web-app",
    storageBucket: "",
    messagingSenderId: "704046594576",
    appId: "1:704046594576:web:3f2ba6dbc99c4e5c"
}

export default firebaseConfig